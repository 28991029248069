<template>

    <div class="text-center">
        <amplify-authenticator id="amplify-auth" :auth-config="authConfig"/>
        <amplify-sign-out v-if="showSignOut"/>
    </div>
</template>
<script lang="ts">
    import {Component, Vue} from "vue-property-decorator";
    //@ts-ignore
    import components from "aws-amplify-vue";
    import {Amplify} from "aws-amplify";

    @Component({
        components: {
            ...components
        }
    })
    export default class Login extends Vue {
        authConfig = {
            signInConfig: {isSignUpDisplayed: false},
        };
        showSignOut = false;

        mounted() {
            Amplify.Auth.currentSession().then((data: any) => {
                this.showSignOut = data.isValid()
            }).catch(() => {
                this.showSignOut = false;
            });
        }
    }
</script>
<style>
    .navbar-nav .nav-item p {
        line-height: inherit;
        margin-left: 5px;
    }
</style>
